<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- Belum Validasi -->
    <b-row>
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Kontrak Belum Validasi</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterTableBv"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadBv"
                :items="listDataBv"
                class="mt-2"
                :busy="isLoadingTable"
                :filter="filterTableBv"
                :per-page="perPageBv"
                :current-page="currentPageBv"
                @filtered="onFilteredBv"
                show-empty
              >
                <template v-slot:cell(aksi)="listDataBv">
                  <div>
                    <router-link
                      v-if="listDataBv.item.idStatusValidasi == 0"
                      class="btn bg-primary rounded-sm"
                      v-b-tooltip="'Validasi'"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.kontrak.detail',
                        params: {
                          idPks: listDataBv.item.idPks,
                          statusValidasi: listDataBv.item.idStatusValidasi
                        }
                      }"
                      ><i class="ri-check-line m-0"></i>Validasi</router-link
                    >
                    <div
                      v-else
                      class="font-weight-bold"
                      :class="
                        listDataBv.item.keteranganValidasi == 'Gagal Validasi'
                          ? 'text-danger'
                          : 'text-success'
                      "
                      disabled
                    >
                      <i class="ri-check-double-line m-0 mr-1"></i
                      >{{ listDataBv.item.keteranganValidasi }}
                    </div>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageBv"
                      :options="pageOptionsBv"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageBv"
                    :total-rows="totalRowsBv"
                    :per-page="perPageBv"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- ./END: Belum Validasi -->
    <!-- Sudah Validasi -->
    <b-row>
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Kontrak Sudah Validasi</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterTableSv"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadSv"
                :items="listDataSv"
                class="mt-2"
                :busy="isLoadingTable"
                :filter="filterTableSv"
                :per-page="perPageSv"
                :current-page="currentPageSv"
                @filtered="onFilteredSv"
                show-empty
              >
                <template v-slot:cell(aksi)="listDataSv">
                  <div>
                    <router-link
                      v-if="listDataSv.item.idStatusValidasi == 0"
                      class="btn bg-primary rounded-sm"
                      v-b-tooltip="'Validasi'"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.kontrak.detail',
                        params: {
                          idPks: listDataSv.item.idPks,
                          statusValidasi: listDataSv.item.idStatusValidasi
                        }
                      }"
                      ><i class="ri-check-line m-0"></i>Validasi</router-link
                    >
                    <div
                      v-else
                      class="font-weight-bold"
                      :class="
                        listDataSv.item.keteranganValidasi == 'Gagal Validasi'
                          ? 'text-danger'
                          : 'text-success'
                      "
                      disabled
                    >
                      <i class="ri-check-double-line m-0 mr-1"></i
                      >{{ listDataSv.item.keteranganValidasi }}
                    </div>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageSv"
                      :options="pageOptionsSv"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageSv"
                    :total-rows="totalRowsSv"
                    :per-page="perPageSv"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- ./END: Sudah Validasi -->
  </b-container>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'menuKhusus.verifikasiDokumen.kontrak',
  computed: {
    ...mapState('authService', ['token'])
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredBv(filteredItems) {
      this.totalRowsBv = filteredItems.length
      this.currentPageBv = 1
    },
    onFilteredSv(filteredItems) {
      this.totalRowsSv = filteredItems.length
      this.currentPageSv = 1
    },
    /**
     * Get data Verifikasi
     */
    async getDataVerifikasi_() {
      this.isLoadingTable = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListDataValidasiKontrak`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataBv = res.data.bv
          this.listDataSv = res.data.sv
          /** Set the initial number of items */
          this.totalRowsBv = this.listDataBv?.length
          this.totalRowsSv = this.listDataSv?.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isLoadingTable = false
      return result
    },
    /**
     * Innit Data
     */
    async innitData() {
      // innit data code...
      await Promise.all([this.getDataVerifikasi_()])
    }
  },
  data() {
    return {
      // Tabel Options
      isLoadingTable: false,
      filterTableBv: null,
      perPageBv: 5,
      pageOptionsBv: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageBv: 1,
      totalRowsBv: 1,

      filterTableSv: null,
      perPageSv: 5,
      pageOptionsSv: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageSv: 1,
      totalRowsSv: 1,
      // List Data
      listDataBv: [],
      listDataSv: [],
      // Table
      theadBv: [
        {
          label: 'Kode KS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Jenis KS',
          key: 'kodeJenisPks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Keterangan',
          key: 'ruangLingkup',
          class: 'text-left w-50',
          sortable: true
        },
        {
          label: 'Nomor KS UT',
          key: 'nomorKsUt',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Mitra',
          key: 'kodeMitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nomor KS Mitra',
          key: 'nomorKsMitra',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      theadSv: [
        {
          label: 'Kode KS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Jenis KS',
          key: 'kodeJenisPks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Keterangan',
          key: 'ruangLingkup',
          class: 'text-left w-50',
          sortable: true
        },
        {
          label: 'Nomor KS UT',
          key: 'nomorKsUt',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Mitra',
          key: 'kodeMitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nomor KS Mitra',
          key: 'nomorKsMitra',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (MENU_ROLE.MENU_KHUSUS_VERIFIKASI_DOKUMEN.includes(String(idUnitKs))) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
